import React, { Component } from 'react';

import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import PartnerForm from './components/PartnerForm';
import Logo from './components/Logo';

class Partner extends Component{

	constructor (){
		super()
		this.state = {
			formSuccess : false,
			isMsgSuccess: true,
			emailcode:''
		}
	}

	formSuccessHandler = (newSuccess,newMsgSuccess,emailcode) =>{
		this.setState({
			formSuccess: newSuccess,
			isMsgSuccess: newMsgSuccess,
			emailcode:emailcode
		});
	}

	render(){
		return(
			<React.Fragment>
				<div className="public-pages">
					<Container>
						{/* After submit add class="d-none" to "row" */}
						<Row >
							<Col lg={12} className="text-center">
								<Logo />
								<p className="lead-desc"><a href="https://contrib.com/">Proud Member of CONTRIB</a> |<a href="https://vnoc.com">Powered by VNOC</a></p>
								<br />
								<br />
							</Col>
							<Col lg={12} className={this.state.formSuccess ? ('d-none') : null }>
								<div className="wrap-box-main mb-3">
									<div className="bg-left-box justify-content-center align-items-center d-flex flex-d-col">
										<h2 className="mb-4 text-md-right">
											Invest in a great domain name as part of your brand strategy and take your business to the next level
										</h2>
										<Row>
											<Col lg={7} className="text-md-right">
												<ul className="list-unstyled">
													<li>
														Secure your online identity
														<i className="fas fa-check ml-2"></i>
													</li>
													<li>
														Enhance your brand equity
														<i className="fas fa-check ml-2"></i>
													</li>
													<li>
														Boost your credibility
														<i className="fas fa-check ml-2"></i>
													</li>
													<li>
														Improve conversion rates
														<i className="fas fa-check ml-2"></i>
													</li>
												</ul>
											</Col>
											<Col lg={5}>
												<Image roundedCircle fluid src="https://cdn.vnoc.com/icons/login-800x800.jpg" alt="login icon" />
											</Col>
										</Row>
									</div>
									<div className="bg-right-box">
										<PartnerForm onchangeform={this.formSuccessHandler} />
										{/* Start:: 1st Step */}
										<div className="mx-n3 bg-item-form ">
											
										</div>
										{/* End */}

										{/* Start:: 2nd Step */}
										<div className="mx-n3 bg-item-form d-none">
											<h2 className="text-center mt-3">Complete your Partnership</h2>
											<p className="text-center mb-4">
												Please complete your profile in order to present your partnership to the domain owner.*
											</p>
											<Form.Group>
												<Form.Label>Website <small className="text-muted">( https:// )</small> <span className="text-danger">*</span></Form.Label>
												<Form.Control type="text" />
												<div className="d-block text-danger small mt-2">
													Please enter data.
												</div>
											</Form.Group>
											<Form.Group>
												<Form.Label>Phone <span className="text-danger">*</span></Form.Label>
												<Form.Control type="text" />
												<div className="d-block text-danger small mt-2">
													Please enter data.
												</div>
											</Form.Group>
											<Form.Group>
												<Form.Label>Country <span className="text-danger">*</span></Form.Label>
												<Form.Control as="select">
													<option value="">Select Country</option>
												</Form.Control>
												<div className="d-block text-danger small mt-2">
													Please enter data.
												</div>
											</Form.Group>
											<Form.Group>
												<Form.Label>City <span className="text-danger">*</span></Form.Label>
												<Form.Control type="text" />
												<div className="d-block text-danger small mt-2">
													Please enter data.
												</div>
											</Form.Group>
											<Form.Group className="mt-3">
												<Row>
													<Col lg={6}>
														<Button variant="danger" className="btn-block py-3">
															BACK
														</Button>
													</Col>
													<Col lg={6}>
														<Button variant="primary" className="btn-block py-3">
															NEXT
														</Button>
													</Col>
												</Row>
											</Form.Group>
										</div>
										{/* End */}

										{/* Start:: 3rd Step */}
										<div className="mx-n3 bg-item-form d-none">
											<h2 className="text-center mt-3">Complete your Offer</h2>
											<p className="text-center mb-4">
												Please complete your profile in order to present your partnership to the domain owner.*
											</p>
											<Form.Group>
												<Form.Label>Company Title <span className="text-danger">*</span></Form.Label>
												<Form.Control as="select">
													<option value="">Select Partnership Type</option>
												</Form.Control>
												<div className="d-block text-danger small mt-2">
													Please enter data.
												</div>
											</Form.Group>
											<Form.Group>
												<Form.Label>Company Description <span className="text-danger">*</span></Form.Label>
												<Form.Control as="textarea" rows="3" />
												<div className="d-block text-danger small mt-2">
													Please enter data.
												</div>
											</Form.Group>
											<Form.Group>
												<Form.Label>Company Image URL <small className="text-muted">( https:// )</small> <span className="text-danger">*</span></Form.Label>
												<Form.Control type="text" />
												<div className="d-block text-danger small mt-2">
													Please enter data.
												</div>
											</Form.Group>
											<Form.Group>
												<Form.Label>Company URL <small className="text-muted">( https:// )</small> <span className="text-danger">*</span></Form.Label>
												<Form.Control type="text" />
												<div className="d-block text-danger small mt-2">
													Please enter data.
												</div>
											</Form.Group>
											<Form.Group>
												By clicking the button below, I understand that the offer that I am about to submit is binding and I agree to Ecorp domain name sales and rental terms.
											</Form.Group>
											<Form.Group className="mt-3">
												<Row>
													<Col lg={6}>
														<Button variant="danger" className="btn-block py-3">
															BACK
														</Button>
													</Col>
													<Col lg={6}>
														<Button variant="primary" className="btn-block py-3">
															SUBMIT
														</Button>
													</Col>
												</Row>
											</Form.Group>
											{/* Start:: Loader style after submit form */}
											<div className="preloader-loading d-none"></div>
											{/* End */}
										</div>
										{/* End */}
									</div>
								</div>
							</Col>
						</Row>
						<Row className={this.state.isMsgSuccess ? ('d-none') : ('text-center') }>
							<Col lg={{span: 6, offset: 3}} >
								<img width="150" className="img-fluid" src="https://image.flaticon.com/icons/svg/2618/2618116.svg" alt="partnership" />
								<h2>Thank you for your <br />Partnership Application!</h2>
								<p>
									You are now minutes away to joining {window.Configs.domain} team. All you need to do right now is click the link in the Verification email that we have just sent you. If you still haven’t received it, please check your spam inbox. Your verification link will redirect you to our Marketplace hub where you can login and check out your application status.
								</p>
								<p>
									You can now take part in actually building out an asset by sending proposals, partnering with brands, joining teams.  
								</p>
								<a id="gotocontrib" rel="noopener noreferrer" className="btn btn-success btn-lg btn-block" target="_blank" href={"https://www.contrib.com/account/autologinforms?email="+this.state.emailcode+"&form=partnership"}>Go to your contrib account</a>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default Partner;