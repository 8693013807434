import React from 'react'

function Logo() {
 
    if(window.Configs.logo===''){
		return <div className="buy-domain-name">{window.Configs.domain.charAt(0).toUpperCase() + window.Configs.domain.slice(1)}</div>
	}else{
		return <img className="buy-logo-domain img-fluid" src={window.Configs.logo} alt="" />
	}
  
}

export default Logo
