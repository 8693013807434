import React, { Component } from 'react';

class LoadingState extends Component {
	render(){
		return(
			<>
				<div className="preloader-loading"></div>
			</>
		);
	}
}

export default LoadingState;