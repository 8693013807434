import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

class NoMatch extends Component {
	render() {
		return (
			<React.Fragment>
				<div id="notfound">
				    <div className="notfound">
				        <div className="notfound-404"></div>
				        <h1>404</h1>
				        <h2>Oops! Page Not Be Found</h2>
				        <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
				        <Button as={Link} to="/" variant="primary">Back to homepage</Button>
				    </div>
				</div>
			</React.Fragment>
		);
	}
}

export default NoMatch;