import React from 'react'

function FeaturedSite({site, id}) {
  return (
  <React.Fragment>
	<div className="wrap-marketplace-box-item">
		<a className="wmbi-img-logo" href={"https://"+site.domain_name}>
			<img src={site.logo} className="img-responsive img-sitelogo" alt={site.domain_name} title={site.domain_name} />
		</a>
		<h3 className="marg-m-ttlTop text-capitalize wmbi-ttle ellipsis">
			{site.domain_name}
		</h3>
		<p className="p-marg-btm">
			Join our exclusive community of like minded people on
		</p>
		<p>
			<a target="_blank" rel="noopener noreferrer" href={"https://"+site.domain_name}>{site.domain_name}</a>
		</p>
		<ul className="list-inline ul-wmbi-zero">
			<li className="list-inline-item">
				<a className="btn btn-success btn-lg" target="_blank" rel="noopener noreferrer" href={"https://"+site.domain_name}>Visit</a>
			</li>
			<li className="list-inline-item">
				<a className="btn btn-success btn-lg" target="_blank" rel="noopener noreferrer" href={"https://contrib.com/brand/details/"+site.domain_name}>
					Details
				</a>
			</li>
		</ul>
	</div>
	</React.Fragment>
  )
}

export default FeaturedSite
