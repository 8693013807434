import React, { Component } from 'react';
class About extends Component{
	componentDidMount() {
		console.log('about componentDidMount');
		if (typeof window.ReloadPage === 'function') { console.log('fire ReloadPage'); window.ReloadPage();}
	}
	render(){
		return(
			<React.Fragment>
				<style>
				{`
				.navbar {
						display: none;
					}
				.public-pages {
					min-height: calc(100vh - 50px);
					background-position: 50%;
					background-size: cover;
					background-repeat: no-repeat;
					padding-top: 10px;
					padding-bottom: 100px;
					position: relative;
					}
					.overlay {
					background-color: rgba(0, 0, 0, 0.85);
					}
					.jumbotron {
					background: none;
					color: #ffffff;
					padding: 2rem 5rem;
					}
					.about-title {
					font-size: 4rem;
					font-weight: 600;
					}
					.about-left-title {
					font-family: 'Caveat', cursive;
					border-bottom: 1px dashed #ffffff;
					padding-bottom: 1rem;
					}
					.footer-dark-1, .footer-dark-2 {
						display: none;
					}
				`}
				</style>
				<div className="aboutnew-script"></div>
			</React.Fragment>
		);
	}
}

export default About;